<template>
  <v-card flat tile>
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-img
          alt="Welcome to the future of energy"
          cover
          height="400"
          src="@/assets/image-grid.jpg"
          transition="fade-transition"
          width="100%"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "HomeImages",
  data: () => ({
    //
  }),
};
</script>
