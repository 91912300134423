<template>
  <v-card class="kpis pt-10" color="primary" flat tile style="padding-bottom: 160px">
    <v-container class="py-8 py-md-3">
      <v-row>
        <v-col
          color="primary"
          class="text-alt text-caption font-weight-black text-center text-md-right text-lowercase"
          flat
          cols="12"
        >
          highlighted operational projects
        </v-col>
      </v-row>

      <v-row>
        <v-col color="primary" flat cols="12">
          <v-col class="d-flex align-center flex-column text-center" cols="12">
            <v-img
              alt="Palo Verde"
              contain
              height="192"
              max-width="100%"
              src="@/assets/palo_verde.png"
              transition="fade-transition"
              width="400"
            />
          </v-col>

          <v-col class="d-flex align-center flex-column text-center" cols="12">
            <span class="btn-porfolio text-body-2 font-weight-bold">
              on energy storage portfolio
            </span>
          </v-col>

          <v-col class="d-flex align-center flex-column text-center mb-6" cols="12">
            <span class="text-h5 v-application text-weight-bold">Texas, USA</span>
          </v-col>
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col
          class="order-1 order-md-2 pa-0"
          cols="12"
          md="3"
          sm="6"
          v-for="(item, i) in orderedItems"
          :index="i"
          v-bind:key="item.id"
        >
          <CardFeatured
            :title="item.title"
            :image="item.image"
            :index="item.index"
            :location="item.location.toLocaleLowerCase()"
            :highlights="item.highlights"
            :front="item.front"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style lang="scss">
.btn-porfolio {
  border: 2px solid #000;
  padding: 5px 20px;
  border-radius: 20px;
  font-weight: 900;
}
</style>

<script>
import CardFeatured from "@/components/projects/CardFeatured.vue";

export default {
  name: "Featured",
  components: { CardFeatured },
  data: () => ({
    items: [
      {
        image: "projects/32_winmil.jpg",
        index: "1",
        title: "winmil",
        location: "Angleton, Texas.",
        highlights:
          "Strategically connected to Centerpoint Energy Crosby Spencer-Reit substation, it is poised to deliver clean and reliable energy to the Electric Reliability Council of Texas (ERCOT) grid",
        front: false,
      },
      {
        image: "projects/31_spencer1.jpg",
        index: "2",
        title: "spencer",
        location: "Pasadena, Texas.",
        highlights:
          "Strategically connected to Centerpoint Energy Crosby Spencer-Reit substation, it is poised to deliver clean and reliable energy to the Electric Reliability Council of Texas (ERCOT) grid",
        front: false,
      },
      {
        image: "projects/30_cougar1.jpg",
        index: "3",
        title: "crosby",
        location: "Crosby, Texas",
        highlights:
          "Strategically connected to Centerpoint Energy Crosby substation, it is poised to deliver clean and reliable energy to the Electric Reliability Council of Texas (ERCOT) grid",
        front: false,
      },
      {
        image: "projects/29_maverick1.jpg",
        index: "4",
        title: "maverick",
        location: "Alvin, Texas.",
        highlights:
          "Strategically connected to Centerpoint Energy Mustang Bayou substation, it is poised to deliver clean and reliable energy to the Electric Reliability Council of Texas (ERCOT) grid",
        front: false,
      },
    ],
    dialog: false,
  }),
  computed: {
    orderedItems: function () {
      return _.orderBy(this.items, "index", "desc");
    },
  },
};
</script>
