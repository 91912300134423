<template>
  <v-card color="black" dark flat tile style="padding: 80px 0">
    <v-container style="position: relative; z-index: 2">
      <v-row>
        <v-col class="text-center" cols="12">
          <div class="mx-auto text-h3 font-weight-semibold mb-10 text-center">
            Project Map
          </div>

          <div
            class="mx-auto text-h5 font-weight-semibold mb-8 primary--text text-center"
          >
            Operating/In-Construction Projects
          </div>
        </v-col>
      </v-row>

      <v-row class="mb-12">
        <v-col cols="12">
          <v-img
            alt="On.Energy Map"
            class="mx-auto"
            contain
            src="@/assets/map_yellow.jpg"
            height="100%"
            transition="fade-transition"
            width="100%"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Map",
  data: () => ({
    //
  }),
};
</script>
