<template>
  <v-row>
    <v-col class="text-center" cols="12">
      <div class="mx-auto text-h3 font-weight-semibold mb-10 text-center primary--text">
        company culture
      </div>
    </v-col>

    <v-col cols="12">
      <v-row style="position: relative">
        <carousel-3d
          :border="0"
          :clickable="false"
          :disable3d="true"
          :loop="true"
          space="460"
          height="280"
          ref="mycarousel"
          width="450"
        >
          <slide :index="0">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/1.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/1.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="1">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/2.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/2.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="2">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/3.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/3.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="3">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/4.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/4.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="4">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/5.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/5.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="5">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/6.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/6.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="6">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/7.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/7.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="7">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/8.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/8.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="8">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/9.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/9.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="9">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/10.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/10.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="10">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/11.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/11.jpg" />
              </v-card>
            </v-dialog>
          </slide>

          <slide :index="11">
            <v-dialog v-model="dialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="On.Energy Culture"
                  height="280px"
                  cover
                  src="@/assets/culture/12.jpg"
                />
              </template>

              <v-card>
                <v-img alt="On.Energy Culture" cover src="@/assets/culture/12.jpg" />
              </v-card>
            </v-dialog>
          </slide>
        </carousel-3d>

        <div class="custom-controls d-flex align-center justify-space-between">
          <v-btn
            @click="goPrev()"
            aria-label="Go to previous image"
            color="white"
            icon
            x-large
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-btn
            @click="goNext()"
            aria-label="Go to next image"
            color="white"
            icon
            x-large
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  name: "CompanyCulture",
  components: {
    Carousel3d,
  },
  data: () => ({
    //
  }),
  methods: {
    goNext() {
      this.$refs.mycarousel.goNext();
    },
    goPrev() {
      this.$refs.mycarousel.goPrev();
    },
  },
};
</script>
