<template>
  <v-card color="black" flat tile style="padding-top: 120px; padding-bottom: 120px">
    <ContactUs />
  </v-card>
</template>

<script>
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "Contact",
  components: {
    ContactUs,
  },
  data: () => ({
    //
  }),
};
</script>
