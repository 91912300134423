<template>
  <v-row>
    <v-col class="d-flex flex-column flex-wrap" cols="12">
      <div class="mb-6 text-h3 text-md-h2 font-weight-bold black--text">
        <span class="primary--text"> projects </span><br />
        under<br />
        construction
      </div>
    </v-col>

    <v-col cols="12">
      <v-row style="position: relative">
        <carousel-3d
          :border="0"
          :clickable="false"
          :disable3d="true"
          space="388"
          height="520"
          ref="mycarousel"
          width="388"
        >
          <slide v-for="(item, i) in items" :index="i" v-bind:key="item.id">
            <Card
              class="px-3"
              :title="item.title"
              :front="item.front"
              :image="item.image"
              :application="item.application"
              :details="item.details"
              :location="item.location"
            />
          </slide>
        </carousel-3d>

        <div class="custom-controls d-flex align-center justify-space-between">
          <v-btn
            @click="goPrev()"
            aria-label="Go to previous image"
            color="black"
            icon
            x-large
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-btn
            @click="goNext()"
            aria-label="Go to next image"
            color="black"
            icon
            x-large
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Card from "@/components/projects/Card.vue";
import infoProjectsUnderConst from "@/api/infoProjectsUnderConst.json";

import _ from "lodash";

export default {
  name: "ProjectsUnderConst",
  components: {
    Card,
  },
  data() {
    return {
      items: infoProjectsUnderConst,
    };
  },
  methods: {
    goNext() {
      this.$refs.mycarousel.goNext();
    },
    goPrev() {
      this.$refs.mycarousel.goPrev();
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-3d-slide {
  background-color: white;
  opacity: 0 !important;
  z-index: 970 !important;

  &.current {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.right-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.left-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }
}
</style>
