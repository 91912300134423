<template>
  <v-row style="position: relative; z-index: 2">
    <v-col cols="12">
      <a href="https://www.on.energy/shop/" target="_blank">
        <v-img
          alt="On.Energy Shop"
          class="d-md-none"
          contain
          src="@/assets/shop__link--mobile.jpg"
          transition="fade-transition"
          width="100%"
        />

        <v-img
          alt="On.Energy Shop"
          class="d-none d-md-block"
          contain
          src="@/assets/shop__link2.jpg"
          transition="fade-transition"
          width="100%"
        />
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Shop",
  data: () => ({
    //
  }),
};
</script>
