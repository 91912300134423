<template>
  <v-row>
    <v-col class="d-flex flex-column flex-wrap" cols="12">
      <div class="mb-6 text-h3 text-md-h2 font-weight-bold black--text">
        <span class="primary--text"> operational </span><br />
        projects
      </div>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="4" v-for="(item, i) in items" :key="i">
          <Card
            class="px-3"
            :title="item.title"
            :front="item.front"
            :image="item.image"
            :application="item.application"
            :details="item.details"
            :location="item.location"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Card from "@/components/projects/Card.vue";
import infoProjectsOperational from "@/api/infoProjectsOperational.json";

import _ from "lodash";

export default {
  name: "ProjectsUnderConst",
  components: {
    Card,
  },
  data() {
    return {
      items: infoProjectsOperational,
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel-3d-slide {
  background-color: white;
  opacity: 0 !important;
  z-index: 970 !important;

  &.current {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.right-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.left-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }
}
</style>
