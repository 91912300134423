<template>
  <v-card color="primary" flat tile height="100%">
    <v-container style="max-width: 500px">
      <v-row>
        <v-col class="text-h6 black--text text-uppercase" cols="6">
          {{ title }}
        </v-col>

        <v-col class="text-h6 black--text text-right text-uppercase" cols="6">
          {{ size }}
        </v-col>

        <v-col class="pa-0" cols="12">
          <v-divider />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "ListFeatured",
  data: () => ({
    //
  }),
  props: {
    index: {
      type: String,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
    },
  },
};
</script>
