<template>
  <div>
    <VideoHome />

    <v-card
      class="kpis"
      color="primary"
      flat
      tile
      style="padding-top: 80px; padding-bottom: 80px"
    >
      <HomeKpis />
    </v-card>

    <HomeImages />

    <v-card
      class="welcome"
      color="black"
      flat
      tile
      style="overflow: hidden; padding-top: 80px; padding-bottom: 80px"
    >
      <Welcome />
    </v-card>

    <v-card color="primary" flat tile>
      <EnergySolutions />
    </v-card>

    <v-card class="software" color="black" flat tile>
      <ComponentSoftware />
    </v-card>

    <v-container style="padding-top: 80px; padding-bottom: 160px">
      <LatestProjects />
    </v-container>

    <v-card
      color="black"
      dark
      flat
      tile
      style="overflow: hidden; padding-top: 160px; padding-bottom: 160px"
    >
      <Clients />
    </v-card>

    <v-card color="black" dark flat tile style="overflow: hidden">
      <Shop />
    </v-card>

    <v-card
      color="black"
      flat
      tile
      style="overflow: hidden; padding-top: 80px; padding-bottom: 80px"
    >
      <ContactUs />
    </v-card>
  </div>
</template>

<script>
import VideoHome from "@/components/VideoHome.vue";
import HomeKpis from "@/components/HomeKpis.vue";
import HomeImages from "@/components/HomeImages.vue";
import Welcome from "@/components/Welcome.vue";
import EnergySolutions from "@/components/EnergySolutions.vue";
import ComponentSoftware from "@/components/ComponentSoftware.vue";
import LatestProjects from "@/components/LatestProjects.vue";
import Clients from "@/components/Clients.vue";
import Shop from "@/components/Shop.vue";
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "Home",
  components: {
    VideoHome,
    HomeKpis,
    HomeImages,
    Welcome,
    EnergySolutions,
    ComponentSoftware,
    LatestProjects,
    Clients,
    Shop,
    ContactUs,
  },
  data: () => ({
    //
  }),
};
</script>
